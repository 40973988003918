import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'compras',
    loadChildren: () => import('./paginas/compras/compras.module').then( m => m.ComprasPageModule)
  },
  {
    path: 'graficos',
    loadChildren: () => import('./paginas/graficos/graficos.module').then( m => m.GraficosPageModule)
  },
  {
    path: 'vinosylic',
    loadChildren: () => import('./paginas/vinosylic/vinosylic.module').then( m => m.VinosylicPageModule)
  },
  {
    path: 'carros',
    loadChildren: () => import('./paginas/carros/carros.module').then( m => m.CarrosPageModule)
  },
  {
    path: 'mapaped',
    loadChildren: () => import('./paginas/mapaped/mapaped.module').then( m => m.MapapedPageModule)
  },
  {
    path: 'vinosupd',
    loadChildren: () => import('./paginas/vinosupd/vinosupd.module').then( m => m.VinosupdPageModule)
  },
  {
    path: 'licores',
    loadChildren: () => import('./paginas/licores/licores.module').then( m => m.LicoresPageModule)
  },
  {
    path: 'licoresupd',
    loadChildren: () => import('./paginas/licoresupd/licoresupd.module').then( m => m.LicoresupdPageModule)
  },
  {
    path: 'banners',
    loadChildren: () => import('./paginas/banners/banners.module').then( m => m.BannersPageModule)
  },
  {
    path: 'bannersupd',
    loadChildren: () => import('./paginas/bannersupd/bannersupd.module').then( m => m.BannersupdPageModule)
  },
  {
    path: 'vinosadd',
    loadChildren: () => import('./paginas/vinosadd/vinosadd.module').then( m => m.VinosaddPageModule)
  },
  {
    path: 'licoresadd',
    loadChildren: () => import('./paginas/licoresadd/licoresadd.module').then( m => m.LicoresaddPageModule)
  },
  {
    path: 'subirimg',
    loadChildren: () => import('./paginas/subirimg/subirimg.module').then( m => m.SubirimgPageModule)
  },
  {
    path: 'bannersadd',
    loadChildren: () => import('./paginas/bannersadd/bannersadd.module').then( m => m.BannersaddPageModule)
  },
  {
    path: 'parametros',
    loadChildren: () => import('./paginas/parametros/parametros.module').then( m => m.ParametrosPageModule)
  },
  {
    path: 'oedenar',
    loadChildren: () => import('./paginas/oedenar/oedenar.module').then( m => m.OedenarPageModule)
  },
  {
    path: 'ofertas',
    loadChildren: () => import('./paginas/ofertas/ofertas.module').then( m => m.OfertasPageModule)
  },
  {
    path: 'ofertasadd',
    loadChildren: () => import('./paginas/ofertasadd/ofertasadd.module').then( m => m.OfertasaddPageModule)
  },
  {
    path: 'ofertasupd',
    loadChildren: () => import('./paginas/ofertasupd/ofertasupd.module').then( m => m.OfertasupdPageModule)
  },
  {
    path: 'cupones',
    loadChildren: () => import('./paginas/cupones/cupones.module').then( m => m.CuponesPageModule)
  },
  {
    path: 'cuponesadd',
    loadChildren: () => import('./paginas/cuponesadd/cuponesadd.module').then( m => m.CuponesaddPageModule)
  },
  {
    path: 'cuponesupd',
    loadChildren: () => import('./paginas/cuponesupd/cuponesupd.module').then( m => m.CuponesupdPageModule)
  },
  {
    path: 'cervezas',
    loadChildren: () => import('./paginas/cervezas/cervezas.module').then( m => m.CervezasPageModule)
  },
  {
    path: 'cervezasadd',
    loadChildren: () => import('./paginas/cervezasadd/cervezasadd.module').then( m => m.CervezasaddPageModule)
  },
  {
    path: 'cervezasupd',
    loadChildren: () => import('./paginas/cervezasupd/cervezasupd.module').then( m => m.CervezasupdPageModule)
  },
  {
    path: 'bannersw',
    loadChildren: () => import('./paginas/bannersw/bannersw.module').then( m => m.BannerswPageModule)
  },
  {
    path: 'bannerswadd',
    loadChildren: () => import('./paginas/bannerswadd/bannerswadd.module').then( m => m.BannerswaddPageModule)
  },
  {
    path: 'bannerswupd',
    loadChildren: () => import('./paginas/bannerswupd/bannerswupd.module').then( m => m.BannerswupdPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
